export const checkPresidentName = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('会长名称不能为空'));
    }
    callback();
};
export const checkCompanyName = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('公司名称不能为空'));
    }
    callback();
};
export const checkCompanyAccount = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('公司账户不能为空'));
    }
    callback();
};
export const checkEnterprise = (rule, value, callback) => {

    if (value === '') {
        callback(new Error('企业信用代码不能为空'));
    }

    if (!Number.isInteger(value)) {

        callback(new Error('企业信用代码只能填写数字类型'));
    }
    callback();
};

export const checkgroupName = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('小组ID不能为空'));
    }
    callback();
};
export const checkisActived = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('必须选择一项'));
    }
    callback();
};
export const checkgroupMap = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('组名称不能为空'));
    }
    callback();
};


export const checkempolyeeName = (rule, value, callback) => {

    let reg = /^(?:[\u4e00-\u9fa5·]{2,16})$/
    if (!value) {
        return callback(new Error('员工姓名不能为空'));
    }
    if (!reg.test(value)) {
        return callback(new Error('员工姓名只能由中文组成'));
    }
    callback();
};
export const checkempolyeeGender = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('必须选择一项'));
    }
    callback();
};
export const checkempolyeeStuffName = (rule, value, callback) => {
    let reg = /^(?:[\u4e00-\u9fa5·]{1,16})$/
    if (value === '') {
        callback(new Error('员工昵称不能为空'));
    }
    if (!reg.test(value)) {
        callback(new Error('员工昵称只能由中文组成'));
    }
    callback();
};
export const checkempolyeeSalted = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('密码不能为空'));
    }
    callback();
};
export const checkempolyeeStuffCode = (rule, value, callback) => {
    let reg = /^[a-z0-9]+$/
    if (value === '') {
        callback(new Error('员工账号不能为空'));
    }

    if (!reg.test(value)) {
        return callback(new Error('员工账号仅支持输入小写字母'))
    }
    callback();
};

export const checkMessageTitle = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('员工账号不能为空'));
    }
    callback();
};
