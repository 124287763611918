<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="add-president-cnt">
        <el-form
          :model="empolyeeForm"
          status-icon
          :rules="rules"
          ref="empolyeeForm"
          label-width="150px"
          class="employee-formRule"
        >
          <el-form-item label="员工姓名:" prop="names">
            <el-input v-model="empolyeeForm.names"></el-input>
          </el-form-item>
          <el-form-item label="员工账号:" prop="stuffCode">
            <el-input v-model="empolyeeForm.stuffCode"></el-input>
          </el-form-item>
          <el-form-item label="员工昵称:" prop="stuffName" >
            <el-input v-model="empolyeeForm.stuffName"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="salted" type="password">
            <el-input v-model="empolyeeForm.salted"></el-input>
          </el-form-item>
          <el-form-item label="性别:" prop="gender">
            <el-radio v-model="empolyeeForm.gender" label="1">男</el-radio>
            <el-radio v-model="empolyeeForm.gender" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="empolyeeForm.remark" type="textarea"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="groupSubmitForm('empolyeeForm')"
              >添加</el-button
            >
            <el-button @click="groupResetForm('empolyeeForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import {
  checkempolyeeName,
  checkempolyeeGender,
  checkempolyeeStuffName,
  checkempolyeeStuffCode,
  checkempolyeeSalted,
} from '@/util/proofread';
import { getEmployeeList, addStffList } from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
export default {
  components: {
    Combination,
  },
  data() {
    return {
      getCookie,
      rules: {
        names: [
          { validator: checkempolyeeName, trigger: 'blur', required: true },
        ],
        gender: [
          { validator: checkempolyeeGender, trigger: 'change', required: true },
        ],
        stuffName: [
          {
            validator: checkempolyeeStuffName,
            trigger: 'blur',
            required: true,
          },
        ],
        salted: [
          {
            validator: checkempolyeeSalted,
            trigger: 'blur',
            required: true,
          },
        ],
        stuffCode: [
          {
            validator: checkempolyeeStuffCode,
            trigger: 'blur',
            required: true,
          },
        ],
      },
      empolyeeForm: {
        names: '',
        stuffName: '',
        stuffCode: '',
        remark: '',
        gender: '',
        salted: '',
      },
      title: '添加员工信息',
    };
  },
  methods: {
    groupSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addfomations();
        } else {
          return false;
        }
      });
    },
    groupResetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async addfomations() {
      try {
        let {
          names,
          stuffName,
          stuffCode,
          remark,
          gender,
          salted,
        } = this.empolyeeForm;
        let params = {
          name: names,
          stuffName,
          stuffCode,
          remark,
          gender: Number(gender),
          salted,
          sessionId: this.getCookie('sessionId'),
        };
        let { data, msg, code } = await addStffList(params);

        if (code !== 200 && msg !== 'success') {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: '添加信息成功',
        });
        this.$router.push({
          path: '/employee',
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {},
};
</script>
<style lang="less">
.employee-formRule {
  width: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .add-select {
    width: 350px;
  }
}
.add-president-cnt {
  display: flex;
  justify-content: center;
}
</style>
