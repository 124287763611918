<template>
  <div class="list-header">
    <i class="list-header-i"></i>
    <span class="title-span">{{ listName }}</span>
  </div>
</template>
<script>
export default {
  props: {
    listName: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less">
.list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  border-bottom: 1px solid #e8ebec;
  .list-header-i {
    width: 4px;
    background-color: #32323a;
    height: 20px;
    margin-right: 10px;
  }
  .title-span {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
